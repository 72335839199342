<template>
  <div>
    <el-dialog
      title="重新发布售卖房型"
      :visible.sync="dialogFormVisible"
      width="710px"
      :close-on-click-modal="false"
    >
      <div  class="majiatit">售卖房型</div>
      <div>
        <el-checkbox
          :indeterminate="isIndeterminate1"
          v-model="checkAll1"
          @change="handleCheckAllChange1"
          >全选</el-checkbox
        >
        <div style="margin: 15px 0"></div>
        <el-checkbox-group
          v-model="checkedlist1"
          @change="handleCheckedCitiesChange1"
        >
          <el-checkbox
            v-for="item in roomBaseVoList"
            :label="item.id"
            :key="item.id"
          >
            {{ item.roomName }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="majiatit">马甲：</div>
      <div>
        <el-checkbox
          :indeterminate="isIndeterminate"
          v-model="checkAll"
          @change="handleCheckAllChange"
          >全选</el-checkbox
        >
        <div style="margin: 15px 0"></div>
        <el-checkbox-group
          v-model="checkedlist"
          @change="handleCheckedCitiesChange"
        >
          <el-checkbox
            v-for="item in horseVestBaseVoList"
            :label="item.id"
            :key="item.id"
          >
            {{ item.horseName }}( {{ item.platformId | formatPlatformId }})
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handAddData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getMatchingVestAndRoomInfoById_api,sendRoom_api } from "../../../apis/room";
export default {
  data() {
    return {
      dialogFormVisible: false,
      horseVestBaseVoList: [],
      checkAll: false,
      isIndeterminate: true,
      checkedlist: [],
      horseVestBaseIdVoList: [],

      roomBaseVoList: [],
      checkAll1: false,
      isIndeterminate1: true,
      checkedlist1: [],
      roomBaseIdVoList: [],

      roomTypeId: "",
    };
  },
  filters: {
    formatPlatformId(val) {
      if (val == 1) {
        return "艺龙";
      } else if (val == 2) {
        return "飞猪";
      } else if (val == 3) {
        return "美团";
      } else if (val == 4) {
        return "去哪儿";
      } else if (val == 5) {
        return "携程";
      } else if (val == 6) {
        return "百度";
      } else if (val == 7) {
        return "萝卜";
      }else if (val == 8) {
        return "天下房仓";
      }
    },
  },
  methods: {
    async open(roomTypeId) {
      this.roomTypeId = roomTypeId;
      this.checkedlist = [];
      this.checkedlist1 = [];
      let { code, data } = await getMatchingVestAndRoomInfoById_api({
        roomTypeId: roomTypeId,
      });
      if (code == 0) {
        this.horseVestBaseIdVoList = [];
        this.roomBaseIdVoList = [];
        this.horseVestBaseVoList = data.horseVestBaseVoList;
        data.horseVestBaseVoList.forEach((item) => {
          this.horseVestBaseIdVoList.push(item.id);
        });
        this.roomBaseVoList = data.roomBaseVoList;
        this.dialogFormVisible = true;
        data.roomBaseVoList.forEach((item) => {
          this.roomBaseIdVoList.push(item.id);
        });
      }
    },
    cleanFn() {
      this.dialogFormVisible = false;
    },
    async handAddData() {
      console.log(this.checkedlist)
      if(this.checkedlist.length==0){
       this.$message.warning('请选择马甲')
      }else{
         let {code,data}=await sendRoom_api({
            roomTypeId:this.roomTypeId,
            vestIds:this.checkedlist.join(','),
            roomIds:this.checkedlist1.join(','),
        })
        if(code==0){
              this.$message.success(data);
               this.dialogFormVisible = false;
        }

      }
      
    },
    handleCheckAllChange1(val) {
      this.checkedlist1 = val ? this.roomBaseIdVoList : [];
      this.isIndeterminate1 = false;
    },
    handleCheckedCitiesChange1(value) {
      let checkedCount = value.length;
      this.checkAll1 = checkedCount === this.roomBaseVoList.length;
      this.isIndeterminate1 =
        checkedCount > 0 && checkedCount < this.roomBaseVoList.length;
    },

    handleCheckAllChange(val) {
      this.checkedlist = val ? this.horseVestBaseIdVoList : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.horseVestBaseVoList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.horseVestBaseVoList.length;
    },
  },
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
.majiatit{
    margin-top: 30px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 500;
}
</style>>