<template>
  <!-- 二次拦截 -->
  <div>
    <el-dialog :title="diaTitle" width="1000px" :visible.sync="dialogFormVisible" top="10rem"
      :close-on-click-modal="false" @closed="cleanDataFn">
      <div>
        <div class="rommListBox">
          <div class="leftbox">房型</div>
          <div class="rightbox">
            <div style="margin-bottom: 10px">
              <el-checkbox style="font-weight: 400" :indeterminate="indeterminate" v-model="ischeckAll"
                @change="handleCheckAllChange">
                全选
              </el-checkbox>
              <el-checkbox style="font-weight: 400" v-model="fanCheck" @change="handFanCheck">
                反选
              </el-checkbox>
            </div>

            <div class="rightboxcontentbox" v-for="(item, topIndex) in arrRoomBigList" :key="item.roomTypeId">
              <div class="hotelTitleName">
                <el-checkbox :indeterminate="item.indeterminate" v-model="item.preSelected"
                  @change="handleCheckTwoChange(topIndex, item.firstId, $event)">{{ item.roomTypeName }}</el-checkbox>
              </div>
              <div style="margin-left: 20px" v-for="ctem in item.list" :key="ctem.roomId">
                <el-checkbox style="
                    font-weight: 400;
                    font-size: 12px;
                    margin-top: 5px;
                    font-family: Microsoft YaHei;
                  " v-model="ctem.preSelected" @change="
      handleCheckThreeChange(
        topIndex,
        ctem.roomId,
        item.firstId,
        $event
      )
      ">
                  {{ ctem.roomName }}
                </el-checkbox>
              </div>
            </div>
          </div>
        </div>

        <div v-for="(item, index) in timeCount" :key="index" style="margin-top: 30px">
          <div class="leftbox">适用日期</div>
          <div class="rightbox">
            <el-date-picker size="mini" unlink-panels class="width200" style="width: 210px" :clearable="false"
              v-model="item.chooseDate" type="daterange" :picker-options="pickerOptionArr[index]" range-separator="-"
              start-placeholder="开始日期" end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <div class="leftbox">适用星期</div>
          <div class="minddlebox">
            <el-checkbox style="margin-right: 30px" :indeterminate="weekIndeterminate" v-model="ischeckAllWeek"
              @change="handleCheckAllWeekChange">
              全选
            </el-checkbox>
            <el-checkbox-group v-model="checkedWeeks" style="display: inline-block" @change="handleCheckedWeeksChange">
              <el-checkbox v-for="item in weeks" :label="item.id" :key="item.id">{{ item.week }}</el-checkbox>
            </el-checkbox-group>
          </div>
          <!-- <div class="rightbtnbox">
            <el-button size="mini" type="text" @click="addTime(index)"
              >增加适用时间段</el-button
            >
            <el-button
              v-if="index > 0"
              size="mini"
              type="text"
              @click="cleanTime(index)"
              >删除此时间段</el-button
            >
          </div> -->
        </div>
        <div class="leftbox">关联修改</div>
        <div class="rightbox">
          <el-checkbox style="margin-right: 30px" :indeterminate="channelIndeterminate" v-model="ischeckAllChannel"
            @change="handleCheckAllChannelChange">
            全选
          </el-checkbox>
          <el-checkbox-group v-model="checkedChannels" style="display: inline-block"
            @change="handleCheckedChannelsChange">
            <el-checkbox v-for="item in channels" :label="item.hotelVestId" :key="item.hotelVestId">{{ item.horseName
              }}</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="diafooterbtn">
          <el-button @click="cleanFn" style="width: 84px; margin-right: 10%" size="small" type="primary"
            plain>取消</el-button>
          <el-button style="width: 84px" @click="handEditData" size="small" type="primary">保存</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { hotelRoomVestHorse_api } from "../../../apis/room";
import { hotelRoomVestTree_api } from "../../../apis/room";
import { setInterceptPrice_api, cancelInterceptPrice_api } from "../../../apis/room";
export default {
  data() {
    return {
      dialogFormVisible: false,
      fanCheck: false,
      arrRoomBigList: [],
      indeterminate: true,
      weekIndeterminate: false,
      ischeckAll: false,
      allState: "不变",
      state: 0,
      weeks: [
        { week: "周一", id: 1 },
        { week: "周二", id: 2 },
        { week: "周三", id: 3 },
        { week: "周四", id: 4 },
        { week: "周五", id: 5 },
        { week: "周六", id: 6 },
        { week: "周日", id: 0 },
      ],
      channels: [

      ],
      ischeckAllWeek: true,
      checkedWeeks: [1, 2, 3, 4, 5, 6, 0],
      ischeckAllChannel: true,
      channelIndeterminate: false,
      checkedChannels: [],
      timeCount: [{ chooseDate: [new Date(), new Date()] }],
      pickerOptionArr: [{}],
      showTableData: [],
      tableData: [

      ],
      allFangTai: "不变",
      fangTaiData: [
        { label: "不变", value: "" },
        { label: "开房", value: 1 },
        { label: "关房", value: 0 },
        { label: "限时售卖", value: 2 },
      ],
      // allfangLiangType: "不变",
      allRoomAction: "不变",
      allRoomAllotment: 0,
      fangLiangTypeData: [
        { label: "不变", value: "" },
        { label: "加房", value: 1 },
        { label: "减房", value: 2 },
        { label: "总量等于", value: 3 },
        { label: "余量等于", value: 4 },
      ],
      allFangLiang: "",
      allTimeType: "不变",
      timeTypeData: [
        { label: "不变", value: "不变" },
        { label: "修改为", value: "修改为" },
      ],
      allTimeDate: "",
      allTimer: "00:00",
      timeData: [
        { label: "00:00", value: 0 },
        { label: "01:00", value: 1 },
        { label: "02:00", value: 2 },
        { label: "03:00", value: 3 },
        { label: "04:00", value: 4 },
        { label: "05:00", value: 5 },
        { label: "06:00", value: 6 },
        { label: "07:00", value: 7 },
        { label: "08:00", value: 8 },
        { label: "09:00", value: 9 },
        { label: "10:00", value: 10 },
        { label: "11:00", value: 11 },
        { label: "12:00", value: 12 },
        { label: "13:00", value: 13 },
        { label: "14:00", value: 14 },
        { label: "15:00", value: 15 },
        { label: "16:00", value: 16 },
        { label: "17:00", value: 17 },
        { label: "18:00", value: 18 },
        { label: "19:00", value: 19 },
        { label: "20:00", value: 20 },
        { label: "21:00", value: 21 },
        { label: "22:00", value: 22 },
        { label: "23:00", value: 23 },
        { label: "24:00", value: 25 },
      ],
      allAutoOff: "不变",
      autoOffData: [
        { label: "不变", value: 0 },
        { label: "是", value: 1 },
        { label: "否", value: 2 },
      ],
      hotelSonId: "",
      diaTitle: '二次拦截',
      interceptRuleType: 1,

    };
  },
  methods: {
    open(hotelSonId, selectRoomIdArr, type) {
      this.hotelSonId = hotelSonId;
      this.interceptRuleType = type;
      //   console.log(selectRoomIdArr)
      this.getHotelRoomVestHorse(hotelSonId);
      this.getHotelRoomVestTree(hotelSonId, selectRoomIdArr);
      if (this.interceptRuleType == 1) {
        this.diaTitle = '二次拦截'
      } else if (this.interceptRuleType == 2) {
        this.diaTitle = '取消二次拦截'
      }

      this.dialogFormVisible = true;
    },
    //获取单个酒店所有售卖房型的马甲
    async getHotelRoomVestHorse(hotelSonId) {
      let { code, data } = await hotelRoomVestHorse_api({
        hotelSonId: hotelSonId,
      });
      if (code == 0) {
        console.log(data);
        this.channels = data;
        this.tableData = data;
        this.tableData.forEach((item) => {
          this.checkedChannels.push(item.hotelVestId)
        });
        this.showTableData = this.tableData;
      }
    },
    //获取单个酒店的房型树
    async getHotelRoomVestTree(hotelSonId, selectRoomIdArr) {
      let { code, data } = await hotelRoomVestTree_api({
        hotelSonId: hotelSonId,
      });
      if (code == 0) {
        data.forEach((item) => {
          if (item.list) {
            item.list.forEach((ctem) => {
              if (selectRoomIdArr.indexOf(ctem.roomId) != "-1") {
                ctem.preSelected = true;
              }
            });
          }
        });

        this.arrRoomBigList = data;
      }
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    handEditData() {
      this.channels.forEach((item) => {
        if (this.checkedChannels.indexOf(item.hotelVestId) != "-1") {
          item.preSelected = true;
        } else {
          item.preSelected = false;
        }
      });

      let upData = {
        endDate: this.initDate(this.timeCount[0].chooseDate[1]),
        horseVestList: this.channels,
        hotelSonId: this.hotelSonId,
        roomTypeList: this.arrRoomBigList,
        startDate: this.initDate(this.timeCount[0].chooseDate[0]),
        week: this.checkedWeeks.join(","),
      };
      //console.log(JSON.stringify(upData));

      if (this.interceptRuleType == 1) {
        this.setInterceptPrice(upData);
      } else if (this.interceptRuleType == 2) {
        this.cancelInterceptPrice(upData);
      }


    },
    //单个酒店二次拦截
    async setInterceptPrice(setData) {
      let { code, data } = await setInterceptPrice_api(setData);
      if (code == 0) {
        this.$message.success("设置二次拦截成功");
        this.dialogFormVisible = false;
        this.$parent.getRoomInfoById()
      }
    },
    //取消二次拦截
    async cancelInterceptPrice(setData) {
      let { code, data } = await cancelInterceptPrice_api(setData);
      if (code == 0) {
        this.$message.success("取消二次拦截成功");
        this.dialogFormVisible = false;
        this.$parent.getRoomInfoById()
      }

    },
    //格式化时间
    initDate(date) {
      date = new Date(date);

      let year = date.getFullYear();
      let mouth = date.getMonth() + 1;
      if (mouth < 10) {
        mouth = "0" + mouth;
      }
      let day = date.getDate();
      if (day < 10) {
        day = "0" + day;
      }
      return `${year}-${mouth}-${day}`;
    },
    // 一层全选
    handleCheckAllChange(e) {
      let that = this;
      this.ischeckAll = e;
      if (e == true) {
        this.indeterminate = false;
        for (let i = 0; i < that.arrRoomBigList.length; i++) {
          that.arrRoomBigList[i].preSelected = e;
          for (let j = 0; j < that.arrRoomBigList[i].list.length; j++) {
            that.arrRoomBigList[i].list[j].preSelected = e;
          }
        }
      } else {
        this.indeterminate = false;
        for (let i = 0; i < that.arrRoomBigList.length; i++) {
          that.arrRoomBigList[i].preSelected = e;
          for (let j = 0; j < that.arrRoomBigList[i].list.length; j++) {
            that.arrRoomBigList[i].list[j].preSelected = e;
          }
        }
      }
    },
    // 反选
    handFanCheck() {
      console.log(this.arrRoomBigList);

      let countTwo = 0;
      this.arrRoomBigList.forEach((item) => {
        let countOne = 0;

        if (item.list == [] || item.list == null || item.list.length == 0) {
          console.log("满足空数组");
          item.preSelected = !item.preSelected;
          item.indeterminate = false;
          if (item.preSelected == true) {
            countTwo++;
          }
        }
        console.log(item);
        if (item.list && item.list.length > 0) {
          item.list.forEach((ctem) => {
            ctem.preSelected = !ctem.preSelected;
            if (ctem.preSelected) {
              countOne++;
            }
          });

          if (countOne == item.list.length) {
            item.preSelected = true;
            countTwo++;
            item.indeterminate = false;
          } else if (countOne == 0) {
            item.preSelected = false;
            item.indeterminate = false;
          } else {
            item.preSelected = false;
            item.indeterminate = true;
          }
        }
      });
      if (countTwo == this.arrRoomBigList.length) {
        this.ischeckAll = true;
        this.indeterminate = false;
      } else if (countTwo == 0) {
        this.ischeckAll = false;
        this.indeterminate = false;
      } else {
        this.ischeckAll = false;
        this.indeterminate = true;
      }
    },
    // 二层全选
    handleCheckTwoChange(index, id, e) {
      let that = this;
      that.arrRoomBigList[index].preSelected = e;
      if (e == false) {
        this.arrRoomBigList[index].indeterminate = false;
      }
      if (that.arrRoomBigList[index].list) {
        for (let i = 0; i < that.arrRoomBigList[index].list.length; i++) {
          that.arrRoomBigList[index].list[i].preSelected = e;
        }
      }
      this.getIsCheckAll();
    },
    // 三层选择
    handleCheckThreeChange(topIndex, sonId, topId, e) {
      let that = this;
      let childrenArray = that.arrRoomBigList[topIndex].list;
      let tickCount = 0;
      let unTickCount = 0;
      let len = childrenArray.length;
      for (let i = 0; i < len; i++) {
        if (sonId == childrenArray[i].id) {
          childrenArray[i].preSelected = e;
        }
        if (childrenArray[i].preSelected == true) {
          tickCount++;
        }
        if (childrenArray[i].preSelected == false) {
          unTickCount++;
        }
      }
      if (tickCount == len) {
        that.arrRoomBigList[topIndex].preSelected = true;
        that.arrRoomBigList[topIndex].indeterminate = false;
      } else if (unTickCount == len) {
        that.arrRoomBigList[topIndex].preSelected = false;
        that.arrRoomBigList[topIndex].indeterminate = false;
      } else {
        that.arrRoomBigList[topIndex].preSelected = false;
        that.arrRoomBigList[topIndex].indeterminate = true;
      }
      that.getIsCheckAll();
    },

    //选择引起的上层全选框变动
    getIsCheckAll() {
      let that = this;
      let tickCount = 0,
        unTickCount = 0,
        ArrLength = that.arrRoomBigList.length;
      for (let j = 0; j < ArrLength; j++) {
        if (that.arrRoomBigList[j].preSelected == true) {
          tickCount++;
        }
        if (that.arrRoomBigList[j].preSelected == false) {
          unTickCount++;
        }
      }
      if (tickCount == ArrLength) {
        //二级全勾选
        that.ischeckAll = true;
        that.indeterminate = false;
      } else if (unTickCount == ArrLength) {
        //二级全不勾选
        that.ischeckAll = false;
        that.indeterminate = false;
      } else {
        that.ischeckAll = false;
        that.indeterminate = true; //添加一级不确定状态
      }
    },
    // 周的全选
    handleCheckAllWeekChange(val) {
      let weekIds = [];
      this.weeks.forEach((item) => {
        weekIds.push(item.id);
      });
      this.checkedWeeks = val ? weekIds : [];
      this.weekIndeterminate = false;
    },
    // 周的单选
    handleCheckedWeeksChange(value) {
      let checkedCount = value.length;
      this.ischeckAllWeek = checkedCount === this.weeks.length;
      this.weekIndeterminate =
        checkedCount > 0 && checkedCount < this.weeks.length;
    },

    //渠道全选
    handleCheckAllChannelChange(val) {
      console.log(val);
      let channelIds = [];
      this.channels.forEach((item) => {
        channelIds.push(item.hotelVestId);
      });
      this.checkedChannels = val ? channelIds : [];
      this.channelIndeterminate = false;
    },
    //渠道单选
    handleCheckedChannelsChange(value) {
      console.log(value);
      let checkedCount = value.length;
      this.ischeckAllChannel = checkedCount === this.channels.length;
      this.channelIndeterminate =
        checkedCount > 0 && checkedCount < this.channels.length;
    },

    //增加适用时间段
    addTime(index) {
      let that = this;

      let endTime = this.timeCount[index].chooseDate[1];
      this.pickerOptionArr.splice(index + 1, 0, {
        disabledDate: (time) => {
          if (endTime)
            return time.getTime() < new Date(endTime).getTime() + 86400000;
        },
      });
      this.timeCount.splice(index + 1, 0, { chooseDate: "", status: "" });
    },
    cleanTime(index) {
      this.timeCount.splice(index, 1);
    },
    cleanDataFn() {
      Object.assign(this.$data, this.$options.data());
    }

  },
};
</script>

<style lang="less" scoped>
.leftbox {
  display: inline-block;
  width: 50px;
  vertical-align: top;
  margin-left: 30px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  margin-bottom: 20px;
}

.rightbox {
  display: inline-block;
  width: 800px;
  vertical-align: top;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 18px;
  padding-left: 20px;
}

.minddlebox {
  display: inline-block;
  // width: 74%;
  vertical-align: top;
  padding-left: 20px;
}

.rightbtnbox {
  display: inline-block;
  vertical-align: top;
}

.rightboxcontentbox {
  display: inline-block;
  width: 45%;
  vertical-align: top;
}

.width96 {
  width: 96px;
}

.width60 {
  width: 60px;
  margin: 0 10px;
}

.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}

.hotelTitleName {
  margin-bottom: 15px;
  margin: 7.5px 0;

  /deep/ .el-checkbox__label {
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #666666;
    line-height: 18px;
  }
}

/deep/ .el-checkbox__label {
  font-size: 12px;
}

/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
  color: #409eff;
}

.tableHead {
  background: #eeeeee;
  height: 40px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #666666;
  line-height: 40px;
  // margin-top: 30px;
}

.tableItem {
  height: 40px;
  line-height: 40px;
  border: 1px solid #f4f4f4;
  margin-left: 30px;
}

.tableChannel {
  margin-left: 12px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #666666;
}

.rommListBox {
  height: 160px;
  overflow: auto;
}

.channelsListBox {
  height: 300px;
  overflow: auto;
  margin-top: 30px;
}
</style>>
