<template>
  <div class="fontRule">
    <div class="titlebox">携程售卖规则</div>
    <el-row style="margin-top: 10px">
      <el-col class="leftlabelbox" :span="6"> 最晚预定时间 </el-col>
      <el-col :span="3">
        <el-select size="mini" class="width88" v-model="xiechengDisable">
          <el-option label="不变" value="不变"></el-option>
          <el-option label="修改为" value="修改为"></el-option>
        </el-select>
      </el-col>
      <el-col :span="13" v-if="xiechengDisable=='修改为'">
        <el-input
          size="mini"
          v-model="xiechengSellData.lastReserveDate"
          style="width: 80px"
          placeholder="0-999"
        >
        </el-input>
        <span class="marginlar">天前的</span>
        <el-select
          size="mini"
          style="width: 80px"
          v-model="xiechengSellData.lastReserveTime"
        >
          <el-option
            v-for="item in timers"
            :key="item.id"
            :label="item.label"
            :value="item.label"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row class="margintop20">
      <el-col class="leftlabelbox" :span="6"> 最小提前天数 </el-col>
      <el-col :span="3">
        <el-input
          class="width88"
          size="mini"
          v-model="xiechengSellData.minDays"
          placeholder="1"
        ></el-input>
      </el-col>
      <el-col :span="13">
        <span class="marginright10"> 最大提前天数 </span>
        <el-input
          class="width88"
          size="mini"
          v-model="xiechengSellData.maxDays"
          placeholder="1"
        ></el-input>
        <span class="marginlar">连住天数</span>
        <el-input
          class="width88"
          size="mini"
          v-model="xiechengSellData.los"
          placeholder="1"
        ></el-input>
      </el-col>
    </el-row>
    <el-row class="margintop20">
      <el-col class="leftlabelbox" :span="6"> 最早入住时间 </el-col>
      <el-col :span="3">
        <!-- <el-input
          class="width88"
          size="mini"
          v-model="xiechengSellData.checkInFrom"
          placeholder="10：00"
        ></el-input> -->
        <el-select
          size="mini"
          class="width88"
          placeholder="10:00"
          v-model="xiechengSellData.checkInFrom"
        >
          <el-option
            v-for="item in timers"
            :key="item.id"
            :label="item.label"
            :value="item.label"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="13">
        <span class="marginright10"> 最晚入住时间 </span>

        <!-- <el-input
          class="width88"
          size="mini"
          v-model="xiechengSellData.checkInTo"
          placeholder="18：00"
        ></el-input> -->

        <el-select
          size="mini"
          class="width88"
          placeholder="18:00"
          v-model="xiechengSellData.checkInTo"
        >
          <el-option
            v-for="item in timers"
            :key="item.id"
            :label="item.label"
            :value="item.label"
          ></el-option>
        </el-select>

        <span class="marginlar">最晚离店时间</span>
        <!-- <el-input
          class="width88"
          size="mini"
          v-model="xiechengSellData.checkOutTo"
          placeholder="18：00"
        ></el-input> -->

        <el-select
          size="mini"
          class="width88"
          placeholder="14:00"
          v-model="xiechengSellData.checkOutTo"
        >
          <el-option
            v-for="item in timers"
            :key="item.id"
            :label="item.label"
            :value="item.label"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row class="margintop20">
      <el-col class="leftlabelbox" :span="6"> 取消规则 </el-col>
      <el-col :span="3">
        <el-select
          size="mini"
          class="width88"
          v-model="xiechengSellData.cancelType"
        >
          <el-option label="免费取消" value="0"></el-option>
          <el-option label="限时取消" value="1"></el-option>
          <el-option label="不可取消" value="2"></el-option>
        </el-select>
      </el-col>
      <!-- <el-col :span="13">
        <span  class="marginright10">性别限制</span>
        <el-select size="mini" class="width88" v-model="xiechengDisable">
          <el-option label="无" value="无"></el-option>
          <el-option label="男" value="男"></el-option>
          <el-option label="女" value="女"></el-option>
        </el-select>
      </el-col> -->
    </el-row>
    <el-row class="margintop20" v-if="xiechengSellData.cancelType == '1'">
      <el-col class="leftlabelbox" :span="6"> 最晚取消时间 </el-col>
      <el-col :span="4">
        <el-select
          size="mini"
          style="width: 120px"
          v-model="xiechengSellData.cancelDay"
        >
          <el-option
            v-for="item in cancelDayList"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="11">
        <el-select
          size="mini"
          style="width: 80px"
          v-model="xiechengSellData.lastCancelTime"
        >
          <el-option
            v-for="item in timers"
            :key="item.id"
            :label="item.label"
            :value="item.label"
          ></el-option>
        </el-select>
        <span class="marginleft10">不扣款</span>
      </el-col>
    </el-row>
    <el-row class="margintop20" v-if="xiechengSellData.cancelType == '1'">
      <el-col class="leftlabelbox" :span="6"> 逾期扣款 </el-col>
      <el-col class="radiobox" :span="17">
        <el-radio-group
          class="marginleft10"
          size="mini"
          v-model="xiechengSellData.penaltyBasicStandard"
        >
          <el-radio :label="0">扣全款</el-radio>
          <el-radio :label="1">扣首日</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      xiechengDisable: "不变",
      xiechengSellData: {
        cancelDay: "",
        cancelType: "",
        checkInFrom: "",
        checkInTo: "",
        checkOutTo: "",
        earliestArriveTime: "",
        lastCancelTime: "",
        lastReserveDate: "",
        lastReserveTime: "",
        latestArrivalTime: "",
        los: "",
        maxlos: "",
        maxDays: "",
        maxRoomQuantity: "",
        minDays: "",
        minRoomQuantity: "",
        penaltyBasicStandard: "",
        percentage: "",
        platform: "5",
        stayHours: "",
        isHourlyRoom: "否",
        startTime: "",
        endTime: "",
        latestDepartureTime: "",
        minAdvHours: "",
        maxAdvHours: "",
      },
      timers: [
        { label: "00:00", id: 1 },
        { label: "01:00", id: 2 },
        { label: "02:00", id: 3 },
        { label: "03:00", id: 4 },
        { label: "04:00", id: 5 },
        { label: "05:00", id: 6 },
        { label: "06:00", id: 7 },
        { label: "07:00", id: 8 },
        { label: "08:00", id: 9 },
        { label: "09:00", id: 10 },
        { label: "10:00", id: 11 },
        { label: "11:00", id: 12 },
        { label: "12:00", id: 13 },
        { label: "13:00", id: 14 },
        { label: "14:00", id: 15 },
        { label: "15:00", id: 16 },
        { label: "16:00", id: 17 },
        { label: "17:00", id: 18 },
        { label: "18:00", id: 19 },
        { label: "19:00", id: 20 },
        { label: "20:00", id: 21 },
        { label: "21:00", id: 22 },
        { label: "22:00", id: 23 },
        { label: "23:00", id: 24 },
        { label: "24:00", id: 25 },
        { label: "25:00", id: 26 },
        { label: "26:00", id: 27 },
        { label: "27:00", id: 28 },
        { label: "28:00", id: 29 },
        { label: "29:00", id: 30 },
        { label: "30:00", id: 31 },
      ],
      cancelDayList: [],
    };
  },
  created() {
    this.cancelDayList = [];
    for (let i = 0; i < 90; i++) {
      let data = { label: `入住前${i}天`, id: i };
      this.cancelDayList.push(data);
    }
  },
  methods: {
    getRuleData() {
        if(this.xiechengSellData.isHourlyRoom=="否"){
         this.xiechengSellData.isHourlyRoom="false"
       }
      return this.xiechengSellData;
    },
  },
};
</script>

<style lang="less" scoped>
.fontRule{
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;

}
.leftlabelbox {
  text-align: right;
  padding-right: 20px;
  min-height: 28px;
  line-height: 28px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.radiobox {
  line-height: 28px;
  height: 28px;
}
.titlebox {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  margin-top: 20px;
  margin-left: 50px;
}
.width88 {
  width: 95px;
}
.marginlar {
  margin-left: 10px;
  margin-right: 10px;
}
.margintop20 {
  margin-top: 20px;
}
.marginleft10 {
  margin-left: 10px;
}
.marginright10 {
  margin-right: 10px;
}
</style>>
