<template>
    <div>
        <!-- 修改物理房型名称 -->
        <el-dialog title="修改物理房型名称" width="550px" :visible.sync="dialogFormVisible">
            <div>
                <el-form :model="handleData">
                    <el-form-item label="物理房型名称">
                        <el-input v-model="handleData.roomTypeName" autocomplete="off"></el-input>
                    </el-form-item>
                </el-form>
                <div class="diafooterbtn">
                    <el-button @click="cleanFn" style="width: 84px; margin-right: 10%" size="small" type="primary"
                        plain>取消</el-button>
                    <el-button style="width: 84px" @click="handEditData" size="small" type="primary">保存</el-button>
                </div>
            </div>


        </el-dialog>
    </div>
</template>

<script>
import { updateRoomTypeName_api } from '@/apis/room.js'
export default {
    name: "Editroomtypename",
    data() {
        return {
            dialogFormVisible: false,

            handleData: {
                roomTypeId: '',
                roomTypeName: '',
            }
        }
    },
    methods: {
        open(roomTypeId) {
            this.handleData.roomTypeId = roomTypeId;
            this.handleData.roomTypeName = '';
            this.dialogFormVisible = true;
        },
        // 取消
        cleanFn() {
            this.dialogFormVisible = false;
        },
        // 保存
        async handEditData() {
            let { code, data } = await updateRoomTypeName_api(this.handleData);
            if (code == 0) {
                this.$message.success('修改成功');
                this.$parent.getRoomInfoById()
                this.dialogFormVisible = false;
            } else {
                this.$message.error('修改失败');
            }
        },
        // 取消
        cleanFn() {
            this.dialogFormVisible = false;
        },

    },

}
</script>

<style lang="less" scoped></style>