<template>
    <!-- 根据艺龙房型添加物理房型 -->
    <div>
        <el-dialog :visible.sync="dialogFormVisible" width="1200px" top="10rem" :close-on-click-modal="false"
            @closed="cleanDataFn">
            <div slot="title" class="header-title">
                <span style="font-size: 16px">新增物理房型</span>
            </div>
            <div>
                <el-form inline size="mini">
                    <el-form-item label="输入艺龙母ID">
                        <el-input v-model="elongId" placeholder="请输入艺龙母ID" clearable>
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="getRoomType">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
                @change="handleCheckAllChange">全选</el-checkbox>
            <div style="margin: 15px 0;"></div>
            <el-checkbox-group v-model="checkedRooms" @change="handleCheckedCitiesChange">
                <el-row>
                    <el-col class="" :span="8" style="margin-bottom: 10px;" v-for="item in roomtypeList"
                        :key="item.roomTypeId">
                        <el-checkbox :label="item.roomTypeId">
                            <div style="max-width: 300px;">
                                {{ item.roomTypeName }}
                            </div>

                        </el-checkbox>
                        <span class="labelfont" @click.stop="openDetail(item)">详情</span>

                    </el-col>
                </el-row>

            </el-checkbox-group>

            <span slot="footer" class="dialog-footer">
                <el-button @click="cleanDataFn">取 消</el-button>
                <el-button type="primary" @click="saveRoomType">确 定</el-button>
            </span>


        </el-dialog>
        <el-dialog :visible.sync="showDetail" append-to-body width="300px">
            <div slot="title" class="header-title">
                <span style="font-size: 16px">详情</span>
            </div>
            <el-form size="mini" label-width="110px" class="form-box">
                <el-form-item label="房型名称：">
                    <div class="formfont">
                        {{ chooseData.roomTypeName }}
                    </div>
                </el-form-item>
                <el-form-item label="房型数量：">
                    <div class="formfont">
                        {{ chooseData.amount }}间
                    </div>
                </el-form-item>
                <el-form-item label="房型面积：">
                    <div class="formfont">
                        {{ chooseData.area }}
                    </div>
                </el-form-item>
                <el-form-item label="床型：">
                    <div class="formfont">
                        {{ chooseData.bedType }}
                    </div>
                </el-form-item>
                <el-form-item label="上网方式：">
                    <div class="formfont">
                        <span v-if="chooseData.broadnetAccess == 0">
                            无宽带
                        </span>
                        <span v-else-if="chooseData.broadnetAccess == 1">
                            有宽带
                        </span>
                        <span v-else-if="chooseData.broadnetAccess == 2">
                            有WIFI
                        </span>
                    </div>
                </el-form-item>
                <el-form-item label="最大入住人数：">
                    <div class="formfont">
                        {{ chooseData.capacity }}人
                    </div>
                </el-form-item>
                <el-form-item label="楼层：">
                    <div class="formfont">
                        {{ chooseData.floor }}
                    </div>
                </el-form-item>

            </el-form>

            <span slot="footer" class="dialog-footer">

                <el-button type="primary" @click="showDetail = false">确 定</el-button>
            </span>


        </el-dialog>


    </div>
</template>

<script>
import { createLogger } from 'vuex'
import { getRoomTypeListbyelongId_api, addRoomTypeUser_api } from '../../../apis/room'
export default {
    data() {
        return {
            roomtypeList: [],
            isIndeterminate: false,
            checkAll: false,
            checkedRooms: [],
            dialogFormVisible: false,
            hotelSonId: '',
            roomtypeidsList: [],
            showDetail: false,
            chooseData: {},
            roomTypeIds: '',
            elongId:null,


        }
    },
    methods: {
        open(hotelSonId) {
            this.dialogFormVisible = true
             this.hotelSonId = hotelSonId
            // this.getRoomType()

        },
        // 获取艺龙房型
        getRoomType() {
            getRoomTypeListbyelongId_api({
                elongId: this.elongId
            }).then(res => {
                // console.log(res);
                this.roomtypeList = res.data
                this.roomtypeList.forEach(item => {
                    this.roomtypeidsList.push(item.roomTypeId)
                })
                this.dialogFormVisible = true
            })
        },
        // 保存
        saveRoomType() {
            console.log(this.checkedRooms);
            if (this.checkedRooms.length == 0) {
                this.$message({
                    message: '请选择物理房型',
                    type: 'warning'
                });
                return
            }
            this.roomTypeIds = this.checkedRooms.join(',')
            addRoomTypeUser_api({
                hotelSonId: this.hotelSonId,
                roomTypeIds: this.roomTypeIds,
            })
                .then(res => {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    });
                    this.$parent.getRoomInfoById();
                    this.dialogFormVisible = false
                })


        },
        handleCheckAllChange(val) {
            console.log(val)
            this.checkedRooms = val ? this.roomtypeidsList : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(val) {
            let checkedCount = val.length;
            this.checkAll = checkedCount === this.roomtypeList.length;
            this.isIndeterminate = checkedCount > 0 && checkedCount < this.roomtypeList.length;

        },
        cleanDataFn() {
            Object.assign(this.$data, this.$options.data());
        },
        openDetail(item) {
            this.chooseData = item
            this.showDetail = true
        }

    }
}
</script>

<style lang="less" scoped>
.el-form-item .el-form-item--small {
    margin-bottom: 10px;

}

.labelfont {
    margin-left: 10px;
    font-weight: 600;

    line-height: 20px;
    font-size: 14px;
    color: rgb(0, 132, 255);
    cursor: pointer;

}

.formfont {
    color: #727171;
    font-size: 16px;


}

.flexbox {
    display: flex;
    align-items: center;
}

::v-deep .el-checkbox__label {
    vertical-align: text-top;
    white-space: normal;
    word-break: break-all;
    width: 250px;
}
</style>