<template>
  <!-- 设置和恢复售卖房型 -->
  <div>
    <el-dialog
      title="设置/恢复售卖状态"
      width="710px"
      :visible.sync="dialogFormVisible"
      top="10rem"
      :close-on-click-modal="false"
         @closed="cleanDataFn"
    >
      <div>
        <div class="leftbox">设置售卖状态</div>
        <div class="rightbox">
          <el-radio-group v-model="editRoomOneData.state">
            <el-radio :label="0">各渠道统一设置</el-radio>
            <el-radio :label="1">各渠道分别设置</el-radio>
          </el-radio-group>
          <div v-if="editRoomOneData.state == 0">
            <el-radio-group v-model="radio">
              <el-radio :label="0">不变</el-radio>
              <el-radio :label="1">在售</el-radio>
              <el-radio :label="2">停售</el-radio>
              <el-radio :label="3">定时售卖</el-radio>
            </el-radio-group>
          </div>
        </div>
      </div>
      <!-- 统一设置下的定时售卖 -->
      <div v-if="radio == 3 && editRoomOneData.state == 0">
        <div class="leftbox mtab10" >适用日期</div>
        <div class="rightbox mtab10 " >
          <el-date-picker
            size="mini"
            class="width200"
            :clearable="false"
            
            v-model="chooseDate"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="leftbox">售卖状态</div>
        <div class="rightbox" >
          <div>
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              >全周</el-checkbox
            >
            <el-time-picker
              size="mini"
              class="startpicker"
              format="HH:mm"
              v-model="value1"
              placeholder=""
             
              @change="changeAllStartTime()"
            >
            </el-time-picker>
            <span>至</span>
            <el-select class="minddleselect"  size="mini" v-model="weekTimeValue">
              <el-option
                v-for="item in dangorCi"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-time-picker
              size="mini"
              class="endpicker"
             format="HH:mm"
              v-model="value2"
              placeholder=""
              @change="changeAllEndTime()"
            >
            </el-time-picker>
          </div>

          <div v-for="(item, index) in shouMaiWeeks" :key="item.id">
            <el-checkbox
              v-model="item.selected"
              @change="handleCheckChange(item)"
            >
              {{ weeks[index] }}
            </el-checkbox>
            <el-time-picker
              size="mini"
              class="startpicker"
             format="HH:mm"
              v-model="item.startTime"
              placeholder=""
              @change="changeStartTime(item, $event)"
            >
            </el-time-picker>
            <span>至</span>
            <el-select class="minddleselect" size="mini" v-model="weekTimeValue">
              <el-option
                v-for="etem in dangorCi"
                :key="etem.value"
                :label="etem.label"
                :value="etem.value"
              >
              </el-option>
            </el-select>
            <el-time-picker
              size="mini"
              format="HH:mm"
             class="endpicker"
              v-model="item.endTime"
              placeholder=""
              @change="changeEndTime(item, $event)"
            >
            </el-time-picker>
          </div>
        </div>
      </div>
      <!-- 各渠道分别设置 -->
      <div v-if="editRoomOneData.state == 1">
        <div class="leftbox">携程售卖状态</div>
        <div class="rightbox">
          <el-radio-group v-model="radio0">
            <el-radio :label="0">不变</el-radio>
            <el-radio :label="1">在售</el-radio>
            <el-radio :label="2">停售</el-radio>
            <el-radio :label="3">定时售卖</el-radio>
          </el-radio-group>
        </div>
        <!-- 携程定时售卖 -->
        <div v-if="radio0 == 3">
          <div class="leftbox mtab10">适用日期</div>
          <div class="rightbox mtab10">
            <el-date-picker
              size="mini"
                class="width200"
              v-model="chooseDate0"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
          
        </div>
        <div class="leftbox">艺龙售卖状态</div>
        <div class="rightbox">
          <el-radio-group v-model="radio1">
            <el-radio :label="0">不变</el-radio>
            <el-radio :label="1">在售</el-radio>
            <el-radio :label="2">停售</el-radio>
            <el-radio :label="3">定时售卖</el-radio>
          </el-radio-group>
        </div>
        <!-- 艺龙定时售卖 -->
        <div v-if="radio1 == 3">
          <div class="leftbox mtab10">适用日期</div>
          <div class="rightbox mtab10">
            <el-date-picker
              class="width200"
              size="mini"
              v-model="chooseDate1"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="leftbox">去哪售卖状态</div>
        <div class="rightbox">
          <el-radio-group v-model="radio2">
            <el-radio :label="0">不变</el-radio>
            <el-radio :label="1">在售</el-radio>
            <el-radio :label="2">停售</el-radio>
            <el-radio :label="3">定时售卖</el-radio>
          </el-radio-group>
        </div>
        <!-- 去哪定时售卖 -->
        <div v-if="radio2 == 3">
          <div class="leftbox mtab10">适用日期</div>
          <div class="rightbox mtab10">
            <el-date-picker
              size="mini"
                class="width200"
              v-model="chooseDate2"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="leftbox">美团售卖状态</div>
        <div class="rightbox ">
          <el-radio-group v-model="radio3">
            <el-radio :label="0">不变</el-radio>
            <el-radio :label="1">在售</el-radio>
            <el-radio :label="2">停售</el-radio>
            <el-radio :label="3">定时售卖</el-radio>
          </el-radio-group>
        </div>
        <!-- 美团定时售卖 -->
        <div v-if="radio3 == 3">
          <div class="leftbox mtab10">适用日期</div>
          <div class="rightbox mtab10">
            <el-date-picker
              size="mini"
                class="width200"
              v-model="chooseDate3"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="leftbox">飞猪售卖状态</div>
        <div class="rightbox">
          <el-radio-group v-model="radio4">
            <el-radio :label="0">不变</el-radio>
            <el-radio :label="1">在售</el-radio>
            <el-radio :label="2">停售</el-radio>
            <el-radio :label="3">定时售卖</el-radio>
          </el-radio-group>
        </div>
        <!-- 飞猪定时售卖 -->
        <div v-if="radio4 == 3">
          <div class="leftbox mtab10">适用日期</div>
          <div class="rightbox mtab10">
            <el-date-picker
              size="mini"
                class="width200"
              v-model="chooseDate4"
              type="daterange"
              range-separator="-"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="leftbox">携程服务关停后申请恢复售卖</div>
      <div class="rightbox">
        <el-radio-group v-model="editRoomOneData.state1">
          <el-radio :label="0">不变</el-radio>
          <el-radio :label="1">申请恢复售卖</el-radio>
        </el-radio-group>
      </div>
      <div class="leftbox">自动申请恢复售卖</div>
      <div class="rightbox">
        <el-radio-group v-model="editRoomOneData.state2">
          <el-radio :label="0">不变</el-radio>
          <el-radio :label="1">开启</el-radio>
          <el-radio :label="2">关闭</el-radio>
        </el-radio-group>
      </div>
      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px;margin-right:10%; "
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px; "
          @click="handEditData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogFormVisible: false,
      editRoomOneData: {
        state: 0,
        state1: 0,
        state2: 0,
      },
      radio: 0,
      radio0: 0,
      radio1: 0,
      radio2: 0,
      radio3: 0,
      radio4: 0,
      chooseDate: [new Date(), new Date()],
      chooseDate0: [new Date(), new Date()],
      chooseDate1: [new Date(), new Date()],
      chooseDate2: [new Date(), new Date()],
      chooseDate3: [new Date(), new Date()],
      chooseDate4: [new Date(), new Date()],
      isIndeterminate: true,
      checkAll: false,
      weekTimeValue: "当日",
      dangorCi: [
        { label: "当日", value: "0" },
        { label: "次日", value: "1" },
      ],
      weeks: ["周一", "周二", "周三", "周四", "周五", "周六", "周日"],
      shouMaiWeeks: [
        { id: 1, startTime: new Date(), endTime: new Date(), selected: false },
        { id: 2, startTime: new Date(), endTime: new Date(), selected: false },
        { id: 3, startTime: new Date(), endTime: new Date(), selected: false },
        { id: 4, startTime: new Date(), endTime: new Date(), selected: false },
        { id: 5, startTime: new Date(), endTime: new Date(), selected: false },
        { id: 6, startTime: new Date(), endTime: new Date(), selected: false },
        { id: 7, startTime: new Date(), endTime: new Date(), selected: false },
      ],
      value1: new Date(),
      value2: new Date(),
    };
  },
  created() {},
  methods: {
    open() {
      this.dialogFormVisible = true;
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    handEditData() {},
    //统一设置里定时售卖的全选
    handleCheckAllChange(e) {
      let that = this;
      this.checkAll = e;
      this.isIndeterminate = false;
      for (let i = 0; i < that.shouMaiWeeks.length; i++) {
        that.shouMaiWeeks[i].selected = e;
      }
    },
    //单个选择框
    handleCheckChange(item) {
      let that = this;
      console.log(item);
      let checkCount = 0;
      for (let i = 0; i < that.shouMaiWeeks.length; i++) {
        if (that.shouMaiWeeks[i].selected == true) {
          checkCount++;
        }
      }
      this.checkAll = checkCount === that.shouMaiWeeks.length;
      that.isIndeterminate =
        checkCount > 0 && checkCount < that.shouMaiWeeks.length;
    },
    //统一设置内定时售卖全选后调整开始时间
    changeAllStartTime() {
      let that = this;
      if (that.checkAll == true) {
        for (let i = 0; i < that.shouMaiWeeks.length; i++) {
          that.shouMaiWeeks[i].startTime = this.value1;
        }
      }
    },
    //统一设置内定时售卖全选后调整结束时间
    changeAllEndTime() {
      let that = this;
      if (that.checkAll == true) {
        for (let i = 0; i < that.shouMaiWeeks.length; i++) {
          that.shouMaiWeeks[i].startTime = this.value2;
        }
      }
    },
    //  改变单个开始时间
    changeStartTime(data, e) {
      console.log(data, "data");
      console.log(e, "e");
    },
    //改变单个结束时间
    changeEndTime(data, e) {
      console.log(data);
      console.log(e);
    },
     cleanDataFn(){
      Object.assign(this.$data, this.$options.data());
    }
  },
};
</script>

<style lang="less" scoped>
.leftbox {
  width: 25%;
  display: inline-block;
  text-align: right;
  vertical-align: top;
  min-height: 35px;
  line-height: 35px;
}
.rightbox {
  margin-left: 20px;
  width: calc(74% - 20px);
  display: inline-block;
  vertical-align: top;
  min-height: 35px;
  line-height: 35px;
}
.leftboxweek {
  display: inline-block;
  width: 20%;
  vertical-align: top;
}
.rightboxweek {
  display: inline-block;
  width: 79%;
  vertical-align: top;
}
/deep/ .el-radio{
  margin-right: 45px;
}
.startpicker{
  width: 110px;
  margin-left:10px;
  margin-right:10px;
}
.minddleselect{
  width: 80px;
  margin-left:10px;
}
.endpicker{
  width: 110px;
  margin-left:10px;
}
.mtab10{
  margin-top:10px;
  margin-bottom:10px;
}
.width200{
  width: 200px;
}
.diafooterbtn{
  margin-top: 30px;
  text-align: center;
}

</style>>
