<template>
    <div>
        <el-dialog title="修改房间名称" :visible.sync="dialogFormVisible" width="500px" :close-on-click-modal="false"
            @closed="closeDia">
            <el-form size="mini" label-width="120px" :model="handleFn" :rules="rules" ref="handleFn">
                <el-form-item label="房间旧名称：" >
                    <el-input disabled v-model="roomData.roomName" placeholder="请输入房间名称" />
                </el-form-item>

                <el-form-item label="房间新名称：" prop="roomName">
                    <el-input v-model="handleFn.roomName" placeholder="请输入房间名称" />
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">

                <el-button type="primary" @click="submitForm">确 定</el-button>
                <el-button @click="closeDia">取 消</el-button>
            </span>

        </el-dialog>


    </div>
</template>
<script>
import { editRoomName_api } from '@/apis/room'

export default {
    name: 'Editroomnamedia',
    data() {
        return {
            dialogFormVisible: false,
            roomData: {},
            handleFn: {
                roomId: null,
                roomName: null,
            },
            rules:
            {
                roomName: [
                    { required: true, message: '请输入房间名称', trigger: 'blur' },
                ],

            }




        }
    },
    methods: {
        open(room) {
            this.roomData = room
            this.dialogFormVisible = true
            this.handleFn.roomId = room.roomId


        },
        closeDia() {
            this.dialogFormVisible = false
        },
        submitForm() {
            this.$refs.handleFn.validate(valid => {
                if (valid) {
                    this.editRoomName()
                    this.dialogFormVisible = false
                } else {
                    this.$message({ type: 'error', message: '请完善信息' })
                }
            });
        },
        editRoomName() {
            editRoomName_api(this.handleFn).then(res => {
                this.$message({ type: 'success', message: '修改成功' })
                this.$parent.getRoomInfoById()
            })
        }

    },
}
</script>
<style lang="scss" scoped></style>
