<template>
  <!-- 添加物理房型 -->
  <div>
    <el-dialog
      :visible.sync="dialogFormVisible"
      width="810px"
      top="10rem"
      :close-on-click-modal="false"
      @closed="cleanDataFn"
    >
      <div slot="title" class="header-title">
        <span style="font-size: 16px">新增物理房型</span
        ><span style="font-size: 12px; color: #ee4d52"
          >请在新增物理房型前核对物理房型详情（新增后可修改)，以免信息不准确导致客人投诉。</span
        >
      </div>

      <el-form
        size="mini"
        ref="AddRoomForm"
        :rules="AddRoomRule"
        :inline="true"
        :model="addRoomData"
        class="demo-form-inlines"
        style="margin-left: 30px"
      >
        <el-form-item label="房型" prop="roomTypeName">
          <el-input
            size="mini"
            v-model="addRoomData.roomTypeName"
            placeholder="输入房型名称"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="间数">
          <el-input
            size="mini"
            v-model="addRoomData.roomQuantity"
            placeholder="输入房间数量"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="楼层">
          <el-input
            size="mini"
            v-model="addRoomData.floorRange"
            placeholder="输入楼层"
          ></el-input>
        </el-form-item>
        <el-form-item label="面积">
          <el-input
            size="mini"
            v-model="addRoomData.areaRange"
            placeholder="输入房型面积"
          ></el-input>
        </el-form-item>
        <el-form-item label="床型">
          <el-select
            size="mini"
            style="width: 127px; margin-right: 17px"
            v-model="addRoomData.bedType"
            @change="changeBedType"
          >
            <el-option
              v-for="item in bedTypeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.label"
            ></el-option>
          </el-select>

          <el-select
            v-if="addRoomData.bedType != '多张床'"
            size="mini"
            style="width: 127px; margin-right: 17px"
            v-model="addRoomData.bedTotal"
          >
            <el-option
              v-for="item in bedNumberOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input
            v-if="addRoomData.bedType != '多张床'"
            size="mini"
            style="width: 127px"
            v-model="addRoomData.bedSize"
            placeholder="请输入床宽"
          ></el-input>
          <el-button
            v-if="addRoomData.bedType == '多张床'"
            type="primary"
            size="mini"
            @click="addBedJsonItem"
            icon="el-icon-plus"
            >新增</el-button
          >
        </el-form-item>
        <template v-if="addRoomData.bedType == '多张床'">
          <div
            v-for="(item, index) in bedJsonData"
            :key="index"
            class="bedJsonbox"
          >
          <div class="bedJsontitlebox">
             <div>方案{{ Number(index) + 1 }}</div>
             <!-- <div><i class="el-icon-circle-close"></i></div> -->
             <div class="delbtn" @click="delBedJsonItem(index)">删除</div>

          </div>
           
            <el-form-item
           
              label="床型"
              v-for="(etem, index2) in item.bedType"
              :key="index2"
            >
              <el-select
                size="mini"
                style="width: 127px; margin-right: 17px"
                v-model="etem.bedType"
              >
                <el-option
                  v-for="item in bedTypeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>

              <el-select
                size="mini"
                style="width: 127px; margin-right: 17px"
                v-model="etem.num"
              >
                <el-option
                  v-for="item in bedNumberOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
              <el-input
                size="mini"
                style="width: 127px; margin-right: 17px"
                v-model="etem.width"
                placeholder="请输入床宽"
              ></el-input>
              <el-button
                type="primary"
                size="mini"
                @click="addBedJsonItemItem(item, index)"
                icon="el-icon-plus"
                >添加</el-button
              >
              <el-button
                type="danger"
                size="mini"
                @click="delBedJsonItemItem(item, index2)"
                icon="el-icon-delete"
                >删除</el-button
              >
            </el-form-item>
          </div>
        </template>

        <el-form-item label="禁烟">
          <el-select
            size="mini"
            style="width: 110px"
            v-model="addRoomData.isAllowSmoking"
          >
            <el-option
              v-for="item in isAllowSmokingOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="无线网">
          <el-select
            style="width: 110px"
            size="mini"
            v-model="addRoomData.wirelessBroadnet"
          >
            <el-option
              v-for="item in wirelessBroadnetOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="有线网">
          <el-select
            style="width: 110px"
            size="mini"
            v-model="addRoomData.wiredBroadnet"
          >
            <el-option
              v-for="item in wiredBroadnetOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="窗户">
          <el-select
            style="width: 110px"
            size="mini"
            v-model="addRoomData.hasWindow"
          >
            <el-option
              v-for="item in hasWindowOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="人数">
          <el-select
            style="width: 110px"
            size="mini"
            v-model="addRoomData.maxOccupancy"
          >
            <el-option
              v-for="item in maxOccupancyOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>

          <!-- <el-input
            size="mini"
            style="width: 178px"
            v-model="addRoomData.maxOccupancy"
            placeholder="房间允许入住人数"
          ></el-input> -->
        </el-form-item>
        <!-- <el-form-item label="加床">
          <el-select
            style="width: 178px"
            size="mini"
            v-model="addRoomData.isExtraBed"
          >
            <el-option
              v-for="item in isExtraBedOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input
            size="mini"
            style="width: 178px; margin-left: 17px"
            v-model="addRoomData.extraBedFee"
            placeholder="请输入加床费用"
          ></el-input>
        </el-form-item> -->
      </el-form>
      <div class="diafooterbtn">
        <el-button
          :data-link="addRoomData.status"
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handAddData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>


<script>
import { addRoomType_api } from "../../../apis/room";
export default {
  data() {
    return {
      addRoomData: {
        areaRange: "", //房屋面积
        bedSize: "", //床宽
        bedType: "", //床型
        bedTotal: "", //床数量
        extraBedFee: "", //加床费
        floorRange: "", //楼层
        hasWindow: "", //是否有窗
        hotelSonId: "", //系统子酒店ID
        id: "", //物理房型id
        isAllowSmoking: "", //是否可吸烟
        isExtraBed: "", //是否加床
        maxOccupancy: "", //最大入住人数
        roomQuantity: "", //房间数
        roomTypeName: "", //房型名称
        status: 0, //状态0是下架，1为上架
        wiredBroadnet: 0, //有线宽带
        wirelessBroadnet: 0, //无线宽带
        bedJson: [{ bedType: [{ bedType: "", num: "", width: "" }] }],
      },
       bedJsonData: [{ bedType: [{ bedType: "", num: "", width: "" }] }],
      bedNumberOptions: [
        { label: "1张", value: 1 },
        { label: "2张", value: 2 },
        { label: "3张", value: 3 },
        { label: "4张", value: 4 },
        { label: "5张", value: 5 },
        { label: "6张", value: 6 },
        { label: "7张", value: 7 },
        { label: "8张", value: 8 },
        { label: "9张", value: 9 },
        { label: "10张", value: 10 },
        { label: "11张", value: 11 },
        { label: "12张", value: 12 },
        { label: "13张", value: 13 },
        { label: "14张", value: 14 },
        { label: "15张", value: 15 },
        { label: "16张", value: 16 },
        { label: "17张", value: 17 },
        { label: "18张", value: 18 },
        { label: "19张", value: 19 },
        { label: "20张", value: 20 },
      ],
      maxOccupancyOptions: [
        { label: "1人", value: 1 },
        { label: "2人", value: 2 },
        { label: "3人", value: 3 },
        { label: "4人", value: 4 },
        { label: "5人", value: 5 },
        { label: "6人", value: 6 },
        { label: "7人", value: 7 },
        { label: "8人", value: 8 },
        { label: "9人", value: 9 },
        { label: "10人", value: 10 },
        { label: "11人", value: 11 },
        { label: "12人", value: 12 },
        { label: "13人", value: 13 },
        { label: "14人", value: 14 },
        { label: "15人", value: 15 },
        { label: "16人", value: 16 },
        { label: "17人", value: 17 },
        { label: "18人", value: 18 },
        { label: "19人", value: 19 },
        { label: "20人", value: 20 },
      ],
      bedTypeOptions: [
        { label: "大床", value: 1 },
        { label: "特大床", value: 2 },
        { label: "双人床", value: 3 },
        { label: "单人床", value: 4 },
        { label: "圆床", value: 5 },
        { label: "三人床", value: 6 },
        { label: "上下铺", value: 7 },
        { label: "榻榻米", value: 8 },
        { label: "多张床", value: 9 },
        { label: "沙发床", value: 10 },
        { label: "地面床铺", value: 11 },
        { label: "炕", value: 12 },
        { label: "太空舱", value: 13 },
        { label: "水床", value: 14 },
      ],
      isAllowSmokingOptions: [
        { label: "否", value: 1 },
        { label: "是", value: 2 },
        { label: "未知", value: -100 },
      ],
      wirelessBroadnetOptions: [
        { label: "全部房间有且免费", value: 2 },
        { label: "部分房间有且免费", value: 4 },
        { label: "全部房间有且收费", value: 1 },

        { label: "部分房间有且收费", value: 3 },
        { label: "没有", value: 0 },
      ],
      wiredBroadnetOptions: [
        { label: "全部房间有且免费", value: 2 },
        { label: "部分房间有且免费", value: 4 },
        { label: "全部房间有且收费", value: 1 },

        { label: "部分房间有且收费", value: 3 },
        { label: "没有", value: 0 },
      ],
      hasWindowOptions: [
        { label: "无窗", value: 0 },
        { label: "部分有窗", value: 1 },
        { label: "有窗", value: 2 },
        { label: "内窗", value: 3 },
        { label: "部分内窗", value: 4 },
        { label: "飘窗", value: 5 },
        { label: "封闭窗", value: 6 },
        { label: "落地窗", value: 7 },
        { label: "走廊或过道", value: 8 },
        { label: "天窗", value: 9 },
        { label: "装饰性假窗", value: 10 },
        { label: "窗外有墙体或遮挡 ", value: 11 },
        { label: "部分无窗 ", value: 12 },
        { label: "部分有窗且位于走廊或过道 ", value: 13 },
        { label: "部分有窗且为封闭窗 ", value: 14 },
        { label: "部分有窗且窗外有墙体或遮挡 ", value: 15 },
        { label: "部分有窗且为天窗 ", value: 16 },
        { label: "窗户较小 ", value: 18 },
      ],
      isExtraBedOptions: [
        { label: "否", value: 0 },
        { label: "可以加床", value: 1 },
      ],
      AddRoomRule: {},

      dialogFormVisible: false,
    };
  },
  methods: {
    open(data) {
      console.log(data);
      this.getMaxOccupancyOptions()
      this.dialogFormVisible = true;
      this.addRoomData.hotelSonId = data;
    },
    cleanFn() {
      this.dialogFormVisible = false;
    },
    getMaxOccupancyOptions(){
      this.maxOccupancyOptions=[]
        for(let i=1;i<100;i++){
          this.maxOccupancyOptions.push({label:i+"人",value:i})
        }
    },
    handAddData() {
      // console.log(this.addRoomData);
      this.$refs["AddRoomForm"].validate((valid) => {
        if (!valid) {
          return;
        } else {
          this.getAddRoomType();
        }
      });
    },

    async getAddRoomType() {
      if(this.addRoomData.bedType=="多张床"){
        this.addRoomData.bedJson=JSON.stringify(this.bedJsonData)
      }else{
       this.addRoomData.bedJson=null
      }
      let { code, data } = await addRoomType_api(this.addRoomData);
      console.log(code);
      if (code == 0) {
        this.dialogFormVisible = false;
        this.$message.success(data);
        this.$parent.getRoomInfoById();
      }
    },
    //切换床型
    changeBedType(val) {
      console.log(val);
      if (val == "多张床") {
        this.addRoomData.bedTotal =null;
        this.addRoomData.bedSize = null;
      }
    },
    //点击新增
    addBedJsonItem() {
      this.bedJsonData.push({
        bedType: [{ bedType: "", num: "", width: "" }],
      });
    },
    delBedJsonItem(index){
      this.bedJsonData.splice(index,1)
    },
    //点击添加
    addBedJsonItemItem(item, index) {
     
      this.bedJsonData[index].bedType.push({
        bedType: "",
        num: "",
        width: "",
      });
      // item.bedType.push({bedType:'',num:'',width:''})
    },
    delBedJsonItemItem(item, index){
      item.bedType.splice(index,1)
    },

    cleanDataFn() {
      Object.assign(this.$data, this.$options.data());
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-form--inline .el-form-item {
  margin-right: 20px;
}
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
.bedJsonbox {
  // border: 1px solid #000;
  width: 700px;
  background: #ebf1fa;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-top: 10px;
  display: inline-block;
  border-radius: 5px;
}
.bedJsontitlebox{
   width: 685px;
   display: flex;
   justify-content: space-between;
}
.delbtn{
  cursor: pointer;
  color:#F56C6C

}
</style>>
