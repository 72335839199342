<template>
    <!-- 萝卜礼包弹窗 -->
    <div>
        <el-dialog title="礼包管理" :visible.sync="dialogFormVisible" :close-on-click-modal="false" width="1000px">
            <div>
                <el-button type="primary" size="mini" @click="updateGiftbag('add')">
                    <i class="el-icon-plus el-icon--left"></i>
                    新增
                </el-button>
                <div>
                    <el-table size="mini" :data="giftbagList">
                        <el-table-column label="名称" prop="name" align="center"></el-table-column>
                        <el-table-column label="时间" prop="price" align="center">

                            <template slot-scope="scope">
                                <span v-if="scope.row.start">{{ scope.row.start }}</span>-
                                <span v-if="scope.row.end">{{ scope.row.end }}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="描述" prop="description" align="center"></el-table-column>
                        <el-table-column label="操作" align="center">
                            <template slot-scope="scope">
                                <!-- <el-button size="mini" type="text"
                                    @click="updateGiftbag('edit', scope.row)">修改</el-button> -->
                                <el-button size="mini" type="text" @click="delGiftbag(scope.row)">删除</el-button>
                                <!-- <el-button size="mini" type="text" @click="fabuGiftbag(scope.row)">发布</el-button> -->
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </el-dialog>
        <el-dialog :title="addTitle" :visible.sync="addVisible" :close-on-click-modal="false" width="800px">
            <el-form size="mini">
                <el-form-item label="礼包名称">
                    <el-input  v-model="editData.name"  maxlength="10"></el-input>
                </el-form-item>
                <el-form-item label="礼包描述">
                    <el-input type="textarea" :rows="5"  maxlength="500" v-model="editData.description"></el-input>
                </el-form-item>
                <el-form-item label="开始时间">
                    <el-date-picker
                        v-model="editData.start"
                        type="datetime"
                        placeholder="选择日期时间"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    />
                </el-form-item>
                <el-form-item label="结束时间">
                    <el-date-picker
                        v-model="editData.end"
                        type="datetime"
                        placeholder="选择日期时间"
                        value-format="yyyy-MM-dd HH:mm:ss"
                    />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="addVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="handleGiftbag">确 定</el-button>
            </span>
        </el-dialog>
        <!-- <el-dialog title="发布礼包" :visible.sync="fabuVisible" :close-on-click-modal="false" width="900px">
            <div>
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
                    @change="handleCheckAllChange">全选</el-checkbox>
                <div style="margin: 15px 0;"></div>
                <el-checkbox-group v-model="checkedrooms" @change="handleCheckedCitiesChange">
                    <el-row>
                        <el-col :span="8" v-for="item in giftRoomList" :key="item.roomId" style="margin: 5px 0;">
                            <el-checkbox :label="item.roomId">{{ item.roomName }}</el-checkbox>
                        </el-col>
                    </el-row>
                </el-checkbox-group>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button size="mini" @click="fabuVisible = false">取 消</el-button>
                <el-button size="mini" type="primary" @click="handleFabu">确 定</el-button>
            </span>
        </el-dialog> -->
    </div>
</template>

<script>
import {
    giftbagLuoboList_api,
    addLuoboPromotion_api,
    delLuoboGiftbag_api,
    giftRoomList_api,
    sendPromotionFxs_api
} from "../../../apis/room";
export default {
    data() {
        return {
            dialogFormVisible: false,
            addVisible: false,
            fabuVisible: false,
            roomVestId: "",
            giftbagList: [],
            editData: {
                description: "",
                name: "",
                roomSonId: "",
                end:'',
                    start:'',
            },
            addTitle: "",
            giftRoomList: [],
            giftRoomIdList: [],
            isIndeterminate: false,
            checkAll: false,
            checkedrooms: [],
            giftbagData: "",
        };
    },
    created() { },
    methods: {
        open(roomVestId) {
            this.roomVestId = roomVestId;
            this.getGiftbagList();
            this.dialogFormVisible = true;
            this.getRoomList();
        },
        async getGiftbagList() {
            let { code, data } = await giftbagLuoboList_api({
                roomVestId: this.roomVestId,
            });
            if (code == 0) {
                this.giftbagList = data;
            }
        },
        updateGiftbag(type, row) {
            console.log("updateGiftbag");
            if (type == "add") {
                this.addTitle = "新增礼包";
                this.editData = {
                    description: "",
                    name: "",
                    roomSonId: "",
                    end:'',
                    start:'',
                };
            } else if (type == "edit") {
                this.addTitle = "修改礼包";
                this.editData = row;
            }
            this.addVisible = true;
        },
        async handleGiftbag() {
            if (!this.editData.name) {
                this.$message.warning("请输入礼包名称");
            } else if (!this.editData.description) {
                this.$message.warning("请输入礼包描述");
            } else if (!this.editData.start || !this.editData.end) {
                this.$message.warning("请选择时间");
            } else {
                this.editData.roomSonId = this.roomVestId;
                let { code, data } = await addLuoboPromotion_api(this.editData);
                if (code == 0) {
                    this.$message.success("操作成功");
                    this.addVisible = false;
                    this.getGiftbagList();
                }
            }
        },
        delGiftbag(row) {
            this.$confirm("此操作将永久删除该组数据, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.delGiftbagFn(row); // 调用删除功能函数
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
        async delGiftbagFn(row) {
            let { code, data } = await delLuoboGiftbag_api({
                id: row.id,
            });
            if (code == 0) {
                this.$message({
                    type: "success",
                    message: "删除成功!",
                });
                this.getGiftbagList();
            }
        },
        fabuGiftbag(row) {
            this.giftbagData = row;
            this.fabuVisible = true;
        },
        async getRoomList() {
            let { code, data } = await giftRoomList_api({
                roomVestId: this.roomVestId,
            });
            if (code == 0) {
                this.giftRoomList = data;
                this.giftRoomIdList = [];
                this.giftRoomList.forEach((item) => {
                    this.giftRoomIdList.push(item.roomId);
                });
            }
        },
        async handleFabu() {
            if (this.checkedrooms.length == 0) {
                this.$message.warning("请选择房型");
            } else {
                let ids = this.checkedrooms.join(",");
                let { code, data } = await sendPromotionFxs_api({
                    id: this.giftbagData.id,
                    roomVestIds: ids,
                });
                if (code == 0) {
                    this.$message.success("发布成功");

                    this.fabuVisible = false;
                    this.isIndeterminate = false;
                    this.checkAll = false;
                    this.checkedrooms = [];
                    this.$parent.getRoomInfoById();


                }
            }
        },
        handleCheckAllChange(val) {
            console.log(val);
            this.checkedrooms = val ? this.giftRoomIdList : [];
            this.isIndeterminate = false;
        },
        handleCheckedCitiesChange(value) {
            console.log(value);
            let checkedCount = value.length;
            this.checkAll = checkedCount === this.giftRoomList.length;
            this.isIndeterminate =
                checkedCount > 0 && checkedCount < this.giftRoomList.length;
        },
    },
};
</script>

<style></style>