<template>
<!-- 去哪儿渠道的匹配 -->
  <div>
    <el-dialog
    title="自动匹配"
    :visible.sync="dialogFormVisible"
      top="10rem"
      :close-on-click-modal="false"
      width="375px"
      @closed="cleanDataFn"

    >
    <div class="diabox">
      <span style="margin-right:10px;">房型</span>
      <el-input size="mini" style="width:160px;" v-model="defaultData.roomName" placeholder="手动输入匹配房型名称"></el-input>
    </div>
      <div class="diafooterbtn">
        <el-button
          @click="cleanFn"
          style="width: 84px; margin-right: 10%"
          size="small"
          type="primary"
          plain
          >取消</el-button
        >
        <el-button
          style="width: 84px"
          @click="handEditData"
          size="small"
          type="primary"
          >保存</el-button
        >
      </div>

    </el-dialog>

  </div>
</template>

<script>
import { matchingQunarRoomType_api,matchingMeituanRoomType_api } from "../../../apis/room";
export default {
  data(){
    return{
      searchData:'',
      dialogFormVisible:false,
      defaultData:{
        roomTypeVestId:'',
        roomName:''
      },
      typeIndex:'',

    }
  },
  methods:{
       open(roomTypeVestId,type) {
      this.defaultData.roomTypeVestId = roomTypeVestId;
      this.dialogFormVisible = true;
      this.typeIndex=type
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    handEditData() {
      if(this.defaultData.roomName){
        this.defaultData.roomName=this.defaultData.roomName.toString()
        if(this.typeIndex==4){
           this.setMatchingQunarRoomType()
        }else if(this.typeIndex==3){
           this.setMatchingMeituanRoomType()
        }
         
      }else{
        this.$alert('请输入房型名称')
      }
    },
 //匹配去哪儿物理房型(应该是直接写入)
   async setMatchingQunarRoomType(){
      let {code,data} = await matchingQunarRoomType_api(this.defaultData)
      if(code==0){
        this.$message.success(data)
        this.dialogFormVisible=false
         this.$parent.getRoomInfoById()
      }
    },
   async setMatchingMeituanRoomType(){
      let {code,data}= await matchingMeituanRoomType_api({
        roomTypeVestId:this.defaultData.roomTypeVestId,
        name:this.defaultData.roomName
      })
       if(code==0){
        this.$message.success(data)
        this.dialogFormVisible=false
         this.$parent.getRoomInfoById()
      }

    },
      cleanDataFn() {
      Object.assign(this.$data, this.$options.data());
    },
   
  }

}
</script>

<style lang="less" scoped>
.diabox{
  text-align: center;
  height: 28px;
  line-height: 28px;
}
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}
</style>>

