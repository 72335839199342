<template>
  <!-- 匹配（除了去哪儿的其他渠道 -->
  <div>
    <el-dialog title="自动匹配" :visible.sync="dialogFormVisible" top="10rem" :close-on-click-modal="false" width="550px">
      <div class="databox">
        <!-- <el-radio-group v-model="value">
            <div  class="flexitem" v-for="(item, index) in defaultData" :key="index">
              <el-radio :label="item.srid">
                
              </el-radio>
               <span class="contentname">{{ item.name }}</span>
               <el-button size="mini" type="text" @click="openDetailsDia(item)"
            >详情</el-button
          >
            </div>
           

          </el-radio-group> -->

        <div class="flexitem" v-for="(item, index) in defaultData" :key="index">
          <el-checkbox @change="checkChoose(item, $event)" v-model="item.selected"></el-checkbox>
          <span v-if="item.name" class="contentname">{{ item.name | formatHotelName() }}</span>
          <span v-if="item.roomName" class="contentname">{{
      item.roomName | formatHotelName()
    }}</span>
          <el-button size="mini" type="text" @click="openDetailsDia(item)">详情</el-button>
        </div>
      </div>
      <div class="diafooterbtn">
        <el-button @click="cleanFn" style="width: 84px; margin-right: 10%" size="small" type="primary"
          plain>取消</el-button>
        <el-button style="width: 84px" @click="handEditData" size="small" type="primary">保存</el-button>
      </div>
    </el-dialog>
    <el-dialog title="查看物理房型详情" width="412px" :visible.sync="diaForDefaultData">
      <el-row class="margintop20">
        <el-col class="leftlabelbox" :span="10">物理房型：</el-col>
        <el-col v-if="diaData.name" :span="14">{{ diaData.name }}</el-col>
        <el-col v-if="diaData.roomName" :span="14">{{
      diaData.roomName
    }}</el-col>
      </el-row>
      <el-row class="margintop20" v-if="diaData.amount">
        <el-col class="leftlabelbox" :span="10">数量：</el-col>
        <el-col :span="14">{{ diaData.amount }}</el-col>
      </el-row>
      <el-row class="margintop20" v-if="diaData.floor">
        <el-col class="leftlabelbox" :span="10">楼层：</el-col>
        <el-col :span="14">{{ diaData.floor }}</el-col>
      </el-row>
      <el-row class="margintop20" v-if="diaData.area">
        <el-col class="leftlabelbox" :span="10">面积：</el-col>
        <el-col :span="14">{{ diaData.area }}</el-col>
      </el-row>
      <el-row class="margintop20" v-if="diaData.bedType">
        <el-col class="leftlabelbox" :span="10">床型：</el-col>
        <el-col :span="14">{{ diaData.bedType }}</el-col>
      </el-row>
      <el-row class="margintop20" v-if="diaData.maxOccupancy">
        <el-col class="leftlabelbox" :span="10">最大入住人数：</el-col>
        <el-col :span="14">{{ diaData.maxOccupancy }}</el-col>
      </el-row>
      <el-row class="margintop20" v-if="diaData.capacity">
        <el-col class="leftlabelbox" :span="10">最大入住人数：</el-col>
        <el-col :span="14">{{ diaData.capacity }}</el-col>
      </el-row>
      <el-row class="margintop20" v-if="diaData.hasCableInternet">
        <el-col class="leftlabelbox" :span="10">有线网络：</el-col>
        <span v-if="diaData.hasCableInternet == 'Yes'">有</span>
        <span v-else-if="diaData.hasCableInternet == 'No'">无</span>
        <span v-else-if="diaData.hasCableInternet == 'Unknow'">未知</span>
      </el-row>
      <el-row class="margintop20" v-if="diaData.hasWifi">
        <el-col class="leftlabelbox" :span="10">无线wifi：</el-col>
        <span v-if="diaData.hasWifi == 'Yes'">有</span>
        <span v-else-if="diaData.hasWifi == 'No'">无</span>
        <span v-else-if="diaData.hasWifi == 'Unknow'">未知</span>
      </el-row>
      <el-row class="margintop20" v-if="diaData.broadnetAccess">
        <el-col class="leftlabelbox" :span="10">网络连接：</el-col>
        <span v-if="diaData.broadnetAccess == '0'">无宽带</span>
        <span v-else-if="diaData.broadnetAccess == '1'">有宽带</span>
        <span v-else-if="diaData.broadnetAccess == '2'">有wifi</span>
      </el-row>
      <el-row class="margintop20" v-if="diaData.broadnetFee">
        <el-col class="leftlabelbox" :span="10">网络费用：</el-col>
        <span v-if="diaData.broadnetFee == '0'">免费</span>
        <span v-else-if="diaData.broadnetFee == '1'">收费</span>
      </el-row>

      <el-row class="margintop20" v-if="diaData.windowType">
        <el-col class="leftlabelbox" :span="10">是否有窗：</el-col>
        <el-col :span="14">
          <span v-if="diaData.windowType == 0">无窗</span>
          <span v-else-if="diaData.windowType == 1">有窗</span>
          <span v-else-if="diaData.windowType == 2">部分有窗</span>
          <span v-else-if="diaData.windowType == 3">暗装</span>
          <span v-else-if="diaData.windowType == 4">部分暗窗</span>
        </el-col>
      </el-row>
      <el-row class="margintop20" v-if="diaData.hasWindow">
        <el-col class="leftlabelbox" :span="10">是否有窗：</el-col>
        <el-col :span="14">
          <span v-if="diaData.hasWindow == 'Yes'">有窗</span>
          <span v-else-if="diaData.hasWindow == 'No'">无窗</span>
          <span v-else-if="diaData.hasWindow == 'Unknow'">未知</span>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { maRoomTypeForFliggy_api, elongRoomType_api, matchingFlyPigRoomType_api, ctripRoomType_api, matchingCtripRoomType_api, matchingElongRoomType_api, luoboRoomType_api, matchingLuoboRoomType_api,fctxRoomType_api,matchingFctxRoomType_api } from "../../../apis/room";


export default {
  data() {
    return {
      dialogFormVisible: false,
      diaForDefaultData: false,
      platform: "",
      handleData: {
        srid: "",
        roomTypeVestId: "",
        hotelVestId: "",
        name: "",
        roomId: "",
        roomName: "",
      },
      defaultData: "",
      diaData: "",
      value: "",
      searchData: {
        matchingHotelId: "",
        hotelVestId: "",
      },
      dataList: [
        {
          name: "豪华套间1",
          selected: false,
          id: 1,

          data: {
            name: "大床房11",
            jianshu: "11",
            louceng: "3-5",
            mianji: "20",
            chuangxingchang: 1.8,
            chuangnumber: 1,
            maxperson: 2,
            wangluo: 1,
          },
        },
        {
          name: "豪华套间2",
          selected: false,
          id: 2,
          data: {
            name: "大床房12",
            jianshu: "11",
            louceng: "3-5",
            mianji: "20",
            chuangxingchang: 1.8,
            chuangnumber: 1,
            maxperson: 2,
            wangluo: 1,
          },
        },
        {
          name: "豪华套间3",
          selected: false,
          id: 3,
          data: {
            name: "大床房13",
            jianshu: "11",
            louceng: "3-5",
            mianji: "20",
            chuangxingchang: 1.8,
            chuangnumber: 1,
            maxperson: 2,
            wangluo: 1,
          },
        },
        {
          name: "豪华套间4",
          selected: false,
          id: 4,
          data: {
            name: "大床房14",
            jianshu: "11",
            louceng: "3-5",
            mianji: "20",
            chuangxingchang: 1.8,
            chuangnumber: 1,
            maxperson: 2,
            wangluo: 1,
          },
        },
      ],
    };
  },
  filters: {
    formatHotelName(val) {

      if (val.length < 7) {
        return val;
      } else {
        let newstr = val.substring(0, 5);
        return newstr + "...";
      }
    },
  },
  methods: {
    open(roomTypeVestId, hotelSonVestId, hotelVestId, platform) {
      // let searchData={
      //   hotelSonVestId:hotelSonVestId,
      //   hotelVestId:hotelVestId
      // }
      // this.searchData.hotelSonVestId = hotelSonVestId;
      // this.searchData.hotelVestId = hotelVestId;
      this.platform = platform;
      if (platform == 2) {
        let searchData = {
          hotelSonVestId: hotelSonVestId,
          hotelVestId: hotelVestId,
        };
        this.handleData.roomTypeVestId = roomTypeVestId;
        this.handleData.hotelVestId = hotelVestId;
        this.getMaRoomTypeForFliggy(searchData);
      } else if (platform == 1) {
        this.handleData.roomTypeVestId = roomTypeVestId;
        this.getElongRoomType(hotelSonVestId);
      } else if (platform == 5) {
        this.handleData.roomTypeVestId = roomTypeVestId;
        this.getCtripRoomType(hotelSonVestId);
      }
       else if (platform == 7) {
        this.handleData.roomTypeVestId = roomTypeVestId;
        this.getLuoboRoomType(hotelSonVestId);
      }
       else if (platform == 8) {
        this.handleData.roomTypeVestId = roomTypeVestId;
        this.getFctxRoomType(hotelSonVestId);
      }
      this.dialogFormVisible = true;
    },
    //根据飞猪酒店id获取第三方物理房型列表
    async getMaRoomTypeForFliggy(param) {
      let { code, data } = await maRoomTypeForFliggy_api(param);
      if (code == 0) {
        data.forEach((item) => {
          item.selected = false;
        });
        this.defaultData = data;
      }
    },
    //根据艺龙酒店id获取第三方物理房型列表
    async getElongRoomType(param) {
      let { code, data } = await elongRoomType_api({ hotelSonVestId: param });
      if (code == 0) {
        data.forEach((item) => {
          item.selected = false;
        });
        this.defaultData = data;
      }
    },
    //根据萝卜酒店id获取第三方物理房型列表
    async getLuoboRoomType(param) {
      let { code, data } = await luoboRoomType_api({ hotelSonVestId: param });
      if (code == 0) {
        data.forEach((item) => {
          item.selected = false;
        });
        this.defaultData = data;
      }
    },
    //根据天下房仓酒店id获取第三方物理房型列表
    async getFctxRoomType(param) {
      let { code, data } = await fctxRoomType_api({ hotelSonVestId: param });
      if (code == 0) {
        data.forEach((item) => {
          item.selected = false;
        });
        this.defaultData = data;
      }
    },
    //根据携程酒店id获取第三方物理房型列表
    async getCtripRoomType(param) {
      let { code, data } = await ctripRoomType_api({ hotelSonVestId: param });
      if (code == 0) {
        data.forEach((item) => {
          item.selected = false;
        });
        this.defaultData = data;
      }
    },
    // 取消
    cleanFn() {
      this.dialogFormVisible = false;
    },
    // 提交
    handEditData() {
      if (this.platform == 2) {
        this.setMatchingFlyPigRoomType();
      } else if (this.platform == 5) {
        this.setMatchingCtripRoomType();
      } else if (this.platform == 1) {
        this.setMatchingElongRoomType();
      }else if (this.platform == 7) {
        this.setMatchingLuoboRoomType();
      }else if (this.platform == 8) {
        this.setMatchingFctxRoomType();
      }

    },
    //匹配飞猪物理房型
    async setMatchingFlyPigRoomType() {
      if (this.handleData.srid == "" || this.handleData.srid == null) {
        this.$alert("请选择需要匹配的房型");
      } else {
        let { code, data } = await matchingFlyPigRoomType_api(this.handleData);
        if (code == 0) {
          this.$message.success("匹配成功");
          this.dialogFormVisible = false;
          this.$parent.getRoomInfoById()
        }
      }
    },
    //匹配携程物理房型
    async setMatchingCtripRoomType() {
      if (this.handleData.roomId == "" || this.handleData.roomId == null) {
        this.$alert("请选择需要匹配的房型");
      } else {
        let { code, data } = await matchingCtripRoomType_api(this.handleData);
        if (code == 0) {
          this.$message.success("匹配成功");
          this.dialogFormVisible = false;
          this.$parent.getRoomInfoById()
        }
      }
    },

    //匹配艺龙物理房型
    async setMatchingElongRoomType() {
      if (this.handleData.roomId == "" || this.handleData.roomId == null) {
        this.$alert("请选择需要匹配的房型");
      } else {
        let { code, data } = await matchingElongRoomType_api(this.handleData);
        if (code == 0) {
          this.$message.success(data);
          this.dialogFormVisible = false;
          this.$parent.getRoomInfoById()
        }
      }
    },
    //匹配萝卜物理房型
    async setMatchingLuoboRoomType() {
      if (this.handleData.roomId == "" || this.handleData.roomId == null) {
        this.$alert("请选择需要匹配的房型");
      } else {
        let { code, data } = await matchingLuoboRoomType_api(this.handleData);
        if (code == 0) {
          this.$message.success(data);
          this.dialogFormVisible = false;
          this.$parent.getRoomInfoById()
        }
      }
    },
    //匹配天下房仓物理房型
    async setMatchingFctxRoomType() {
      if (this.handleData.roomId == "" || this.handleData.roomId == null) {
        this.$alert("请选择需要匹配的房型");
      } else {
        let { code, data } = await matchingFctxRoomType_api(this.handleData);
        if (code == 0) {
          this.$message.success(data);
          this.dialogFormVisible = false;
          this.$parent.getRoomInfoById()
        }
      }
    },

    //选择
    checkChoose(item, e) {
      //  console.log(item)
      //  console.log(e)
      if (this.platform == 2) {
        if (e == true) {
          this.defaultData.forEach((item) => {
            item.selected = false;
          });
          item.selected = true;
          this.handleData.srid = item.srid;
          this.handleData.name = item.name;
        } else {
          this.handleData.srid = "";
          this.handleData.name = "";
        }
      } else if (this.platform == 5 || this.platform == 1 ||  this.platform == 7 || this.platform == 8) {

        if (e == true) {
          this.defaultData.forEach((item) => {
            item.selected = false;
          });
          item.selected = true;
          this.handleData.roomId = item.roomId;
          this.handleData.roomName = item.roomName;
        } else {
          this.handleData.roomId = "";
          this.handleData.roomName = "";
        }
      }
    },
    //打开内层dia
    openDetailsDia(data) {
      this.diaData = data;
      //console.log(this.diaData);
      this.diaForDefaultData = true;
    },
  },
};
</script>

<style lang="less" scoped>
.diafooterbtn {
  margin-top: 30px;
  text-align: center;
}

.databox {
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}

.flexitem {
  width: 33%;
  //text-align: center;
}

.contentname {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #666666;
  margin: 10px;
}

.leftlabelbox {
  text-align: right;
  padding-right: 30px;
}

.margintop20 {
  margin-top: 20px;
}
</style>>
