<template>
  <div class="fontRule">
    <div class="titlebox">美团售卖规则</div>
    <el-row style="margin-top: 10px">
      <el-col class="leftlabelbox" :span="6"> 最小连住 </el-col>
      <el-col :span="3">
        <el-input
          class="width88"
          size="mini"
          v-model="meituanSellData.los"
          placeholder="1"
        ></el-input>
      </el-col>
      <el-col :span="13">
        <span class="marginright10"> 最大连住 </span>
        <el-input
          class="width88"
          size="mini"
          v-model="meituanSellData.maxlos"
          placeholder="1"
        ></el-input>
      </el-col>
    </el-row>
    <el-row class="margintop20">

      <el-col class="leftlabelbox" :span="6"> 售卖起始小时 </el-col>
      <el-col :span="3">
       
          <el-select
          size="mini"
          class="width88"
          placeholder="10:00"
          v-model="meituanSellData.startTime"
        >
          <el-option
            v-for="item in timers"
            :key="item.id"
            :label="item.label"
            :value="item.label"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="13">
        <span class="marginright10"> 售卖结束小时</span>

       
          <el-select
          size="mini"
          class="width88"
          placeholder="10:00"
          v-model="meituanSellData.endTime"
        >
          <el-option
            v-for="item in timers3"
            :key="item.id"
            :label="item.label"
            :value="item.label"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>

    <el-row class="margintop20">
      <el-col class="leftlabelbox" :span="6"> 最小预定间数 </el-col>
      <el-col :span="3">
        <el-input
          class="width88"
          size="mini"
          v-model="meituanSellData.minRoomQuantity"
          placeholder="1"
        ></el-input>
      </el-col>
      <el-col :span="13">
        <span class="marginright10"> 最大预定间数 </span>
        <el-input
          class="width88"
          size="mini"
          v-model="meituanSellData.maxRoomQuantity"
          placeholder="1"
        ></el-input>
      </el-col>
    </el-row>
    <el-row class="margintop20">
      <el-col class="leftlabelbox" :span="6"> 最小提前天数 </el-col>
      <el-col :span="3">
        <el-input
          class="width88"
          size="mini"
          v-model="meituanSellData.minDays"
          placeholder="1"
        ></el-input>
      </el-col>
      <el-col :span="13">
        <span class="marginright10"> 最大提前天数 </span>
        <el-input
          class="width88"
          size="mini"
          v-model="meituanSellData.maxDays"
          placeholder="1"
        ></el-input>
      </el-col>
    </el-row>
    <el-row class="margintop20">
      <el-col class="leftlabelbox" :span="6"> 最早提前预定小时 </el-col>
      <el-col :span="3">
        <el-input
          class="width88"
          size="mini"
          v-model="meituanSellData.minAdvHours"
          placeholder="1"
        ></el-input>
      </el-col>
      <el-col :span="13">
        <span class="marginright10"> 最晚预定小时 </span>
        <el-input
          class="width88"
          size="mini"
          v-model="meituanSellData.maxAdvHours"
          placeholder="1"
        ></el-input>
      </el-col>
    </el-row>

    <el-row class="margintop20">
      <el-col class="leftlabelbox" :span="6"> 是否为钟点房 </el-col>
      <el-col :span="3">
        <el-select
          size="mini"
          class="width88"
          v-model="meituanSellData.isHourlyRoom"
        >
          <el-option label="是" value="true"></el-option>
          <el-option label="否" value="false"></el-option>
        </el-select>
      </el-col>
      <el-col :span="13" v-show="meituanSellData.isHourlyRoom == 'true'">
        <span class="marginright10">钟点房最早入住时间</span>

        <el-select
          size="mini"
          class="width88"
          placeholder="10:00"
          v-model="meituanSellData.earliestArriveTime"
        >
          <el-option
            v-for="item in timers"
            :key="item.id"
            :label="item.label"
            :value="item.label"
          ></el-option>
        </el-select>
      </el-col>
    </el-row>
    <el-row class="margintop20" v-show="meituanSellData.isHourlyRoom == 'true'">
      <el-col class="leftlabelbox" :span="6"> 钟点房最晚离店时间 </el-col>
      <el-col :span="3">
        <el-select
          size="mini"
          class="width88"
          placeholder="18:00"
          v-model="meituanSellData.latestDepartureTime"
        >
          <el-option
            v-for="item in timers"
            :key="item.id"
            :label="item.label"
            :value="item.label"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="13">
        <span class="marginright10">入住小时数</span>
        <el-input
          class="width88"
          size="mini"
          v-model="meituanSellData.stayHours"
          placeholder="2小时"
        ></el-input>
      </el-col>
    </el-row>

    <el-row class="margintop20">
      <el-col class="leftlabelbox" :span="6"> 取消规则 </el-col>
      <el-col :span="3">
        <el-select
          size="mini"
          class="width88"
          v-model="meituanSellData.cancelType"
        >
          <el-option label="免费取消" value="0"></el-option>
          <el-option label="限时取消" value="1"></el-option>
          <el-option label="不可取消" value="2"></el-option>
        </el-select>
      </el-col>
      <el-col :span="13" v-if="meituanSellData.cancelType == 1">
        <span class="marginright10">最晚取消时间</span>
        <el-select
          size="mini"
          class="marginright10"
          style="width: 120px"
          v-model="meituanSellData.cancelDay"
        >
          <el-option
            v-for="item in cancelDayList"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select
          size="mini"
          class="marginright10"
          style="width: 80px"
          v-model="meituanSellData.lastCancelTime"
        >
          <el-option
            v-for="item in timers"
            :key="item.id"
            :label="item.label"
            :value="item.label"
          ></el-option>
        </el-select>
        <span>不扣款</span>
      </el-col>
    </el-row>
    <el-row class="margintop20" v-if="meituanSellData.cancelType == 1">
      <el-col class="leftlabelbox" :span="6"> 逾期扣款 </el-col>
      <el-col :span="17" class="radiobox">
        <el-radio-group
          class="marginright10"
          size="mini"
          v-model="meituanSellData.penaltyBasicStandard"
        >
          <el-radio :label="0">扣全款</el-radio>
          <el-radio :label="1">扣首日</el-radio>
          <el-radio :label="2">扣百分比</el-radio>

          <el-input
            class="width88"
            size="mini"
            v-model="meituanSellData.percentage"
            placeholder="30"
          ></el-input>
          <!-- <el-radio class="marginleft10" :label="3">限时免费</el-radio> -->
        </el-radio-group>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      meituanSellData: {
        cancelDay: "",
        cancelType: "",
        checkInFrom: "",
        checkInTo: "",
        checkOutTo: "",
        earliestArriveTime: "",
        lastCancelTime: "",
        lastReserveDate: "",
        lastReserveTime: "",
        latestArrivalTime: "",
        los: "",
        maxlos: "",
        maxDays: "",
        maxRoomQuantity: "",
        minDays: "",
        minRoomQuantity: "",
        penaltyBasicStandard: "",
        percentage: "",
        platform: "3",
        stayHours: "",
        isHourlyRoom: "否",
        startTime: "",
        endTime: "",
        latestDepartureTime: "",
        minAdvHours: "",
        maxAdvHours: "",
      },

      timers: [
        { label: "00:00", id: 1 },
        { label: "01:00", id: 2 },
        { label: "02:00", id: 3 },
        { label: "03:00", id: 4 },
        { label: "04:00", id: 5 },
        { label: "05:00", id: 6 },
        { label: "06:00", id: 7 },
        { label: "07:00", id: 8 },
        { label: "08:00", id: 9 },
        { label: "09:00", id: 10 },
        { label: "10:00", id: 11 },
        { label: "11:00", id: 12 },
        { label: "12:00", id: 13 },
        { label: "13:00", id: 14 },
        { label: "14:00", id: 15 },
        { label: "15:00", id: 16 },
        { label: "16:00", id: 17 },
        { label: "17:00", id: 18 },
        { label: "18:00", id: 19 },
        { label: "19:00", id: 20 },
        { label: "20:00", id: 21 },
        { label: "21:00", id: 22 },
        { label: "22:00", id: 23 },
        { label: "23:00", id: 24 },
        { label: "24:00", id: 25 },
      ],
        timers2:[
         { label: "00:00", id: 1 },
        { label: "01:00", id: 2 },
        { label: "02:00", id: 3 },
        { label: "03:00", id: 4 },
        { label: "04:00", id: 5 },
        { label: "05:00", id: 6 },
        { label: "06:00", id: 7 },
        { label: "07:00", id: 8 },
        { label: "08:00", id: 9 },
        { label: "09:00", id: 10 },
        { label: "10:00", id: 11 },
        { label: "11:00", id: 12 },
        { label: "12:00", id: 13 },
        { label: "13:00", id: 14 },
        { label: "14:00", id: 15 },
        { label: "15:00", id: 16 },
        { label: "16:00", id: 17 },
        { label: "17:00", id: 18 },
        { label: "18:00", id: 19 },
        { label: "19:00", id: 20 },
        { label: "20:00", id: 21 },
        { label: "21:00", id: 22 },
        { label: "22:00", id: 23 },
        { label: "23:00", id: 24 },
        { label: "24:00", id: 25 },
        { label: "25:00", id: 26 },
        { label: "26:00", id: 27 },
        { label: "27:00", id: 28 },
        { label: "28:00", id: 29 },
        { label: "29:00", id: 30 },
        { label: "30:00", id: 31 },

      ],
       timers3: [
        { label: "00:00", id: 1 },
        { label: "01:00", id: 2 },
        { label: "02:00", id: 3 },
        { label: "03:00", id: 4 },
        { label: "04:00", id: 5 },
        { label: "05:00", id: 6 },
        { label: "06:00", id: 7 },
        { label: "07:00", id: 8 },
        { label: "08:00", id: 9 },
        { label: "09:00", id: 10 },
        { label: "10:00", id: 11 },
        { label: "11:00", id: 12 },
        { label: "12:00", id: 13 },
        { label: "13:00", id: 14 },
        { label: "14:00", id: 15 },
        { label: "15:00", id: 16 },
        { label: "16:00", id: 17 },
        { label: "17:00", id: 18 },
        { label: "18:00", id: 19 },
        { label: "19:00", id: 20 },
        { label: "20:00", id: 21 },
        { label: "21:00", id: 22 },
        { label: "22:00", id: 23 },
        { label: "23:00", id: 24 },
        { label: "23:59", id: 25 },
      ],
      cancelDayList: [],
    };
  },
  created() {
    this.cancelDayList = [];
    for (let i = 0; i < 90; i++) {
      let data = { label: `入住前${i}天`, id: i };
      this.cancelDayList.push(data);
    }
  },
  methods: {
    getRuleData() {
      if (this.meituanSellData.isHourlyRoom == "否") {
        this.meituanSellData.isHourlyRoom = "false";
      }
      return this.meituanSellData;
    },
  },
};
</script>


<style lang="less" scoped>
.fontRule {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.leftlabelbox {
  text-align: right;
  padding-right: 20px;
  min-height: 28px;
  line-height: 28px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
}
.radiobox {
  line-height: 28px;
  height: 28px;
}
.titlebox {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #333333;
  margin-top: 20px;
  margin-left: 50px;
}
.width88 {
  width: 95px;
}
.marginlar {
  margin-left: 10px;
  margin-right: 10px;
}
.margintop20 {
  margin-top: 20px;
}
.marginleft10 {
  margin-left: 10px;
}
.marginright10 {
  margin-right: 10px;
}
</style>>